import React from 'react';
import css from './SectionHeading.module.css';

const SectionHeading = props => {
  const { richTitle, isExperienceType, currentListing } = props;

  return (
    <div className={css.sectionHeading}>
      <div className={css.heading}>
        <h3 className={css.title}>
          {isExperienceType ? currentListing?.attributes?.publicData?.experienceTitle : richTitle}
        </h3>
      </div>
    </div>
  );
};

export default SectionHeading;
