import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import close from './ModalAsset/close.png';
import { FormattedMessage } from '../../../../../util/reactIntl';
import css from './Modal.module.css';

const Modal = ({
  isOpen,
  onClose,
  children,
  classNameModal,
  classNameButton,
  classNameBtnImage,
}) => {
  useEffect(() => {
    // Hide the scrollbar initially when the component mounts
    document.body.style.overflow = 'hidden';
    return () => {
      // Revert the overflow style when the component unmounts
      document.body.style.overflow = 'auto';
    };
  }, []);

  if (!isOpen) {
    return null;
  }

  const modalClassName = `${css.modalContent} ${css[classNameModal]}`;
  const buttonClassName = `${css.closeButton} ${css[classNameButton]}`;
  const btnImgClassName = `${css.closeButtonImg} ${css[classNameBtnImage]}`;
  return (
    <div className={css.overlay} onClick={onClose}>
      <div className={css.modal}>
        <div className={modalClassName} onClick={e => e.stopPropagation()}>
          <button className={buttonClassName} onClick={onClose}>
            <FormattedMessage id="FieldDateInput.closeDatePicker" />
            <img className={btnImgClassName} src={close} alt="close button" />
          </button>

          {children}
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string, // New prop for custom CSS class name
};

export default Modal;
