import { useEffect, useState } from 'react';
import css from './ListingSuggestions.module.css';
import getCountryCodes from '../../../../translations/countryCodes';
import PrimaryButton from '../../../../components/Button/Button';
import { FormattedMessage } from '../../../../util/reactIntl';
import SectionListingSuggestions from './ListingSuggestions';
import { useSelector } from 'react-redux';
import { locationBounds } from '../../../../util/googleMaps';
import ExperiencesListingSlider from './experienceListingSuggestions/ExperienceListingSlider';

const DiscoverListings = ({ currentListing, openTab }) => {
  const currentLanguage = useSelector(state => state.ui.language);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [openTabDone, setOpenTab] = useState(false);
  const countryCodes = getCountryCodes(currentLanguage === 'en' ? 'en ' : 'de');
  const countryCode = currentListing?.attributes?.publicData?.country ?? '';

  function findCountryNameByCode(countryCode) {
    const country = countryCodes.find(c => c.code === countryCode);
    return country ? country.name : 'Country not found';
  }

  const countryName = findCountryNameByCode(countryCode);
  const isExperienceType =
    currentListing?.attributes?.publicData?.listingType == 'impact-experience';
  const generateMapLink = input => {
    if (input._sdkType === 'LatLngBounds' && input.ne && input.sw) {
      const ne = input.ne;
      const sw = input.sw;
      const bounds = `${ne.lat},${ne.lng},${sw.lat},${sw.lng}`;
      return `/s?bounds=${encodeURIComponent(bounds)}&mapSearch=true`;
    } else {
      console.log('Error');
    }
  };

  useEffect(() => {
    if (openTabDone == false && openTab == true) {
      const listingOrigin = currentListing.attributes.geolocation;
      let mapLink = generateMapLink(locationBounds(listingOrigin, 600000));
      window.open(mapLink, '_blank');
      setOpenTab(true);
    }
  }, [openTabDone]);
  if (!showSuggestions) {
    return (
      <div className={css.discoverContainer}>
        <div
          className={css.showSuggestionsButton}
          data-elbaction="click:click accm-openrecommondations"
        >
          <PrimaryButton
            className={css.showSuggestionsButton}
            type="button"
            onClick={() => setShowSuggestions(true)}
          >
            {isExperienceType ? (
              <FormattedMessage id="sectionSuggestedListings.showExperiencesSuggestions" />
            ) : (
              <FormattedMessage
                id="sectionSuggestedListings.showListingsSuggestions"
                values={{ countryName }}
              />
            )}
          </PrimaryButton>
        </div>
      </div>
    );
  } else if (isExperienceType) {
    return <ExperiencesListingSlider />;
  } else {
    return <SectionListingSuggestions currentListing={currentListing} />;
  }
};

export default DiscoverListings;
