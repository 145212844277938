import React, { useState, useEffect } from 'react';
import { ResponsiveImage } from '../../../../../components';

import Swipe from 'react-easy-swipe';
import css from './SliderMini.module.css';
import Modal from '../../Utils/Modal/Modal';
import Gallery from 'react-photo-gallery';

const SliderMini = ({ images, onMappedArray, onHandleViewGallery, onCloseGallery, onIsOpen }) => {

  const [currentIndex, setCurrentIndex] = useState(0);
  const [showGalleryOnMini, setShowGalleryOnMini] = useState(false);

  const showGallery = () => {
    setShowGalleryOnMini(true);
  };
  const closeGallery = () => {
    setShowGalleryOnMini(false);
  };

  const handlePrevSlide = () => {
    setCurrentIndex(prevIndex => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleNextSlide = () => {
    setCurrentIndex(prevIndex => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className={css.slider}>
      {showGalleryOnMini ? (
        <Modal
          isOpen={showGalleryOnMini}
          onClose={closeGallery}
          classNameModal="galleryModal"
          classNameButton="buttonGallery"
          classNameBtnImage="btnImage"
        >
          {/* using react-photo-gallery  https://www.npmjs.com/package/react-photo-gallery  */}
          <Gallery columns={3} photos={onMappedArray} margin={5} />
        </Modal>
      ) : (
        <Swipe
          onSwipeLeft={handleNextSlide}
          onSwipeRight={handlePrevSlide}
          allowMouseEvents={true}
          tolerance={50}
          delta={10}
          preventDefaultTouchmoveEvent={false}
        >
          {' '}
          <div className={css.slide}>
            {images.map((image, index) => (
              <ResponsiveImage
                onClick={showGallery}
                key={index}
                rootClassName={
                  index === currentIndex ? css.slideImg : `${css.slideImg} ${css.hidden}`
                }
                alt={`Slide ${index + 1}`}
                image={image}
                variants={[
                  image &&
                  'landscape-crop2x' in image.attributes.variants &&
                  !('scaled-large' in image.attributes.variants)
                    ? 'landscape-crop2x'
                    : 'scaled-large',
                ]}
              />
            ))}
          </div>
        </Swipe>
      )}

      <div className={css.imgInfo}>
        <p>
          {currentIndex + 1}/{images.length}
        </p>
      </div>
    </div>
  );
};

export default SliderMini;
