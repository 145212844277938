import css from './faqDisplay.module.css';
import React, { useState } from 'react';

const QuestionsComponent = ({ questions }) => {
  const [expandedQuestion, setExpandedQuestion] = useState(null);

  const handleQuestionClick = question => {
    setExpandedQuestion(expandedQuestion === question ? null : question);
  };
  console.log('5588 questions',questions);
  if (!questions || questions == undefined) {
    return <></>;
  } else {
    return (
      <div className={css.faqContainer}>
        {questions.map((question,index) => (
          <div key={index} className={css.questionContainer}>
            <div className={css.question} onClick={() => handleQuestionClick(question)}>
              <span>{question.question}</span>
              <span className={`${css.arrow} ${expandedQuestion === question ? css.expanded : ''}`}>
                &#9654;
              </span>
            </div>
            {expandedQuestion === question && <div className={css.answer}>{question.answer}</div>}
          </div>
        ))}
      </div>
    );
  }
};

export default QuestionsComponent;
