import React, { useEffect, useState } from 'react';
import css from './ListingSuggestions.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { searchListingsSuggestion } from '../../../SearchPage/SearchPage.duck';
import { createSlug, parse } from '../../../../util/urlHelpers';
import getCountryCodes from '../../../../translations/countryCodes';
import { FormattedMessage } from '../../../../util/reactIntl';
import Pin_Icon from '../../../../assets/Pin_Icon.svg';
import arrowLeft from '../../../../assets/icons/arrows/leftArrowLite.svg';
import arrowRight from '../../../../assets/icons/arrows/rightArrowLite.png';
import PrimaryButton from '../../../../components/Button/Button';
import { useHistory } from 'react-router-dom';

import DiscoverListings from './discoverListingSuggestions';

const SectionListingSuggestions = ({ currentListing }) => {
  const dispatch = useDispatch();
  const [suggestedListings, setSuggestedListings] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [index, setIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [width, setWidth] = useState(0);
  const history = useHistory();

  const countryCodes = getCountryCodes();
  const countryCode = currentListing?.attributes?.publicData?.country ?? '';

  function findCountryNameByCode(countryCode) {
    const country = countryCodes.find(c => c.code === countryCode);
    return country ? country.name : 'Country not found';
  }

  const countryName = findCountryNameByCode(countryCode);
  const listingOrigin = currentListing.attributes.geolocation;
  const search = `address=${countryName}&origin=${listingOrigin.lat}%2C${listingOrigin.lng}`;

  const queryParams = parse(search, {
    latlng: ['origin'],
  });

  let searchParams = {
    page: 1,
    perPage: 40,
    include: ['author', 'images'],
    'fields.listing': [
      'title',
      'geolocation',
      'description',
      'price',
      'publicData.roomtype',
      'publicData.category',
      'publicData.city',
      'publicData.country',
      'publicData.amenities',
      'publicData.customCurrency',
    ],
    'fields.image': [
      'variants.scaled-small',
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop4x',
      'variants.landscape-crop6x',
    ],
  };

  useEffect(() => {
    const handleResize = () => {
      const screenSize = window.innerWidth;
      if (screenSize < 760) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [setWidth]);

  const searchResultsListings = useSelector(state => state.SearchPage.searchResults);

  useEffect(() => {
    if (countryCode != '') {
      dispatch(searchListingsSuggestion({ ...searchParams, ...queryParams }));
    }
  }, [currentListing && currentListing?.attributes?.publicData?.country]);

  const currencyCheck = (customCurrency, shareTribePrice) => {
    if (customCurrency !== undefined) {
      if (customCurrency.includes('Euro')) {
        return shareTribePrice + '€';
      } else if (customCurrency.includes('Dollar')) {
        return shareTribePrice + '$';
      } else {
        return shareTribePrice;
      }
    }
  };

  function listingsPayload(searchResultsListings) {
    let searchListings = searchResultsListings?.listings ? searchResultsListings.listings : null;
    let searchImages = searchResultsListings?.images ? searchResultsListings.images : null;
    let listingsPayload = [];

    if (searchListings != null && searchImages != null) {
      Object.keys(searchListings).forEach(x => {
        const item = searchListings[x];
        if (
          item.id.uuid !== currentListing.id.uuid &&
          item.attributes.publicData.country == currentListing.attributes.publicData.country &&
          item.attributes.title.split('•')[0] !== currentListing.attributes.title.split('•')[0]
        ) {
          let firstImageId = item?.relationships?.images?.data[0] ?? '';
          let firstImagePayload = Object.values(searchImages).find(
            image => image.id.uuid == firstImageId.id.uuid
          );
          let customCurrency = item?.attributes?.publicData?.customCurrency ?? 'Euro';
          let formatPice = currencyCheck(
            customCurrency,
            (item.attributes.price.amount / 100).toString()
          );
          let imgUrl =
            firstImagePayload.attributes.variants['landscape-crop6x']?.url ??
            firstImagePayload.attributes.variants['landscape-crop4x']?.url;
          let newListingObj = {
            title: item.attributes.title.split('•')[0],
            price: formatPice,
            adress:
              item.attributes.publicData.city +
              ', ' +
              findCountryNameByCode(item.attributes.publicData.country),
            id: item.id.uuid,
            link: '/l/' + createSlug(item.attributes.title) + '/' + item.id.uuid,
            imageLink: imgUrl,
          };
          listingsPayload.push(newListingObj);
        }
      });
      let filteredListings = removeDuplicatesKeepCheapest(listingsPayload).slice(0, 5);
      setSuggestedListings(filteredListings);
    }
  }

  const mod = (n, m) => {
    let result = n % m;
    return result >= 0 ? result : result + m;
  };

  function removeDuplicatesKeepCheapest(listingsPayload) {
    const titlePriceMap = new Map();
    for (const listing of listingsPayload) {
      const { title, price } = listing;
      const priceValue = Number(price.replace(/[^0-9.-]+/g, ''));
      let rmvChars = price.replace(/[^0-9\.,]/g, '');
      let replaceX = rmvChars.replace(',', '.');
      let rmvPoints = replaceX.replace(/^([^.]*)\.(.*)\./, '$1$2.');
      let roundedPrice = Math.ceil(rmvPoints);
      let finalPrice = `${roundedPrice}€`;

      if (!titlePriceMap.has(title) || priceValue < titlePriceMap.get(title).price) {
        titlePriceMap.set(title, { ...listing, price: finalPrice });
      }
    }
    const deduplicatedListings = Array.from(titlePriceMap.values());
    setShowSuggestions(true);
    return deduplicatedListings.length ? deduplicatedListings : [];
  }

  const increaseHandler = () => {
    if (suggestedListings) {
      setIndex((index + 1) % suggestedListings.length);
    }
  };

  const decreaseHandler = () => {
    if (suggestedListings) {
      setIndex(mod(index - 1, suggestedListings.length));
    }
  };

  const getCssClass = i => {
    let className = '';
    if (suggestedListings != null) {
      const indexLeft = mod(index - 1, suggestedListings.length);
      const indexRight = mod(index + 1, suggestedListings.length);

      if (isMobile) {
        if (i === indexLeft) {
          className = `${css['mobileSlide']} ${css['mobileSlide-0']}`;
        } else if (i === index) {
          className = ` ${css['mobileSlide-1']}`;
        } else if (i === indexRight) {
          className = `${css['mobileSlide']} ${css['mobileSlide-2']}`;
        } else if (i === (indexRight + 1) % suggestedListings.length) {
          className = `${css['mobileSlide']} ${css['mobileSlide-3']}`;
        } else {
          className = `${css['mobileSlide']} ${css['mobileSlide-3']}`;
        }
      } else {
        if (i === indexLeft) {
          className = `${css['slide']} ${css['slide-0']}`;
        } else if (i === index) {
          className = `${css['slide-1']}`;
        } else if (i === indexRight) {
          className = `${css['slide']} ${css['slide-2']}`;
        } else if (i === (indexRight + 1) % suggestedListings.length) {
          className = `${css['slide']} ${css['slide-3']} `;
        } else if (i === (indexRight + 2) % suggestedListings.length) {
          className = `${css['slide']} ${css['slide-4']} `;
        } else if (i === (indexRight + 3) % suggestedListings.length) {
          className = `${css['slide']} ${css['slide-5']}`;
        } else {
          className = `${css['slide']} ${css['hidden']}`;
        }
      }
    }
    return className;
  };

  const ListingsCards = () => {
    let properListings = [];
    if (suggestedListings && suggestedListings.length > 0) {
      properListings = suggestedListings;
    }
    const spinner = (
      <div className={css.spinnerContainer}>
        <div className={css.spinner}>
          <div className={css.spinnerDot}></div>
          <div className={css.spinnerDot}></div>
          <div className={css.spinnerDot}></div>
        </div>
      </div>
    );
    return (
      <div className={css.allCardsWrapper} >
        {properListings.map((listing, i) => (
          <div className={getCssClass(i)} key={i}>
            <div className={css.outerWrapper}>
              <div
                className={css.innerWrapper}
                onClick={() =>
                  typeof window !== 'undefined' ? window?.open(listing?.link, '_blank') : null
                }
                data-elbaction={`click:click accm-suglist-${listing?.title+listing?.adress}-uuid-${listing?.id}`}
              >
                <div
                  className={css.imageWrapper}
                  onClick={() =>
                    typeof window !== 'undefined' ? window?.open(listing?.link, '_blank') : null
                  }
                >
                  {listing && listing?.imageLink ? (
                    <img
                      className={css.cardImg}
                      src={listing?.imageLink}
                      onClick={() =>
                        typeof window !== 'undefined' ? window?.open(listing?.link, '_blank') : null
                      }
                      alt="socialbnb projects "
                    />
                  ) : (
                    spinner
                  )}
                </div>
                <div className={css.projectTitleWrapper}>
                  <span className={css.projectName}>{listing?.title}</span>
                  <div>
                    <span className={css.priceText}>
                      <FormattedMessage id="landing.accomodations.from" />
                    </span>
                    <span className={css.price}>{' ' + listing?.price}</span>
                    <span className={css.priceText}>
                      {' '}
                      <FormattedMessage id="BookingPanel.night" />
                    </span>
                  </div>
                </div>
                <div className={css.projectLocationWrapper}>
                  <img
                    src={Pin_Icon}
                    width="18px"
                    style={{ paddingRight: '2px' }}
                    alt="socialbnb projects characteristics"
                  ></img>
                  <span className={css.location}>{listing.adress}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  useEffect(() => {
    listingsPayload(searchResultsListings);
  }, [searchResultsListings]);

  if (!showSuggestions) {
    return (
      <div className={css.discoverContainer}>
        <div className={css.loadingContainer}>
          <div className={css.loading}></div>
        </div>
      </div>
    );
  } else if (suggestedListings?.length >= 5 && suggestedListings != undefined) {
  /*   setTimeout(() => {
      if (!scrolled) {
        document.body.scrollTo({
          top: 5500,
          behavior: 'smooth',
        });
        setScrolled(true);
      }
    }, 0); */
    return (
      <>
        <div className={css.mainWrapper} data-elbaction="visible:vis accm-recommendations">
          <div className={css.sectionTitleWrapper}>
            <span className={css.sectionTitle}>
              <FormattedMessage id="sectionSuggestedListings.title" values={{ countryName }} />
            </span>
          </div>
          <div className={css.container}>
            <div className={css.sliderContainer}>
              <div className={css['slider-buttons']}>
                {isMobile ? (
                  <div></div>
                ) : (
                  <img
                    onClick={decreaseHandler}
                    className={css['slider-button']}
                    src={arrowLeft}
                    alt="arrowLeft"
                    data-elbaction='click: clicked swipeListingSuggestions'
                  />
                )}
                <img
                  onClick={increaseHandler}
                  className={css['slider-button']}
                  src={arrowRight}
                  alt="arrowRight"
                  data-elbaction='click: clicked swipeListingSuggestions'
                />
              </div>

              <div className={css.sliderCardsWrapper}>
                <ListingsCards />
              </div>
            </div>
          </div>
          <div className={css.btnWrapper} data-elbaction='click:click accm-backtosearchpage'>
            <PrimaryButton
              className={css.backToSearchBtn}
              type="button"
              onClick={() => history.push('/s')}
            >
              <FormattedMessage id="sectionSuggestedListings.backBtn" />
            </PrimaryButton>
          </div>
        </div>
      </>
    );
  } else if (suggestedListings?.length < 5 || suggestedListings == undefined) {
    return <DiscoverListings currentListing={currentListing} openTab={true} />;
  }
};

export default SectionListingSuggestions;
