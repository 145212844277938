import React from 'react';
import css from './Donate.module.css';
import ExternalLink from '../../../../components/ExternalLink/ExternalLink.js';
import { FormattedMessage } from '../../../../util/reactIntl';
import donateIcon from '../../../../assets/icons/Donate.webp';

const Donate = props => {
  let projectTitle = ' ';
  let donateSite = ' ';

  if (props.currentListing) {
    donateSite = props.currentListing?.author?.attributes?.profile?.publicData?.projectWebsite;
    const projectTitleRaw = props.currentListing?.attributes?.title.split('• ');

    projectTitle = projectTitleRaw[0];
  }

  return (
    <>
      {donateSite ? (
        <div className={css.outerWrapper}>
          <h2>
            <FormattedMessage id="listingPage.donate.title" />
          </h2>

          <div className={css.innerWrapper}>
            <div className={css.elementsWrapper}>
              <div className={css.iconWrapper}>
                <img className={css.icon} src={donateIcon} alt="socialbnb projects donation" />
              </div>

              <div className={css.paragraphWrapper}>
                <p className={css.subtxt}>
                  <FormattedMessage id="listingPage.donate.subTxt" />
                  {projectTitle}
                </p>
              </div>
            </div>

            <div className={css.btnWrapper}>
              <ExternalLink href={donateSite}>
                <button className={css.heroButton} data-elbaction='click:click accm-donatebutton'>
                  <FormattedMessage id="listingPage.donateBtn" />
                </button>
              </ExternalLink>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Donate;
