import React from 'react';
import css from './bulletPointsFormatter.module.css';

const BulletPointsFormatter = ({ text }) => {
  if(!text){
    return <></>
  }
  const bulletPointChars = new Set(['-', '•', '*', '+']);
  const bulletPointRegex = /(\s*[-•*+]\s*)/;

  const segments = text.split(bulletPointRegex);

  const formattedLines = [];
  let currentLine = segments[0].trim();

  for (let i = 1; i < segments.length; i += 2) {
    const bullet = segments[i].trim();
    const segment = segments[i + 1] ? segments[i + 1].trim() : '';

    if (bulletPointChars.has(bullet.charAt(0)) && !(currentLine && /[a-zA-Z0-9]$/.test(currentLine))) {
      if (currentLine) {
        formattedLines.push(currentLine);
      }
      formattedLines.push(<div key={formattedLines.length} className={css.bulletPoint}>{bullet} {segment}</div>);
      currentLine = '';
    } else {
      currentLine += ` ${bullet} ${segment}`;
    }
  }

  if (currentLine) {
    formattedLines.push(currentLine);
  }

  return (
    <div className={css.formattedText}>
      {formattedLines.map((line, index) => (
        <div key={index}>{line}</div>
      ))}
    </div>
  );
};

export default BulletPointsFormatter;
