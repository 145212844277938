import React from 'react';
import { useSelector } from 'react-redux';
import css from './Languages.module.css';

import langImg from '../../../../assets/icons/languages.webp';

const Languages = ({ languages, otherLanguagesEn, otherLanguagesDe }) => {
  const englishNames = ['english', 'spanish', 'french', 'german', 'portuguese', 'chinese'];
  const germanNames = [
    'Englisch',
    'Spanisch',
    'Französisch',
    'Deutsch',
    'Portugiesisch',
    'Chinesisch',
  ];

  const currentLanguage = useSelector(state => state.ui.language);

  let transformedLanguages = [];
  let otherLanguages = [];

  if (currentLanguage === 'de') {
    transformedLanguages = languages.map(name => {
      const index = englishNames.indexOf(name.toLowerCase());
      if (otherLanguagesDe !== 'Übersetzung hinzufügen') {
        otherLanguages = otherLanguagesDe;
      }

      return index !== -1 ? germanNames[index] : name;
    });
  } else if (currentLanguage === 'en') {
    transformedLanguages = languages.map(name => {
      const index = englishNames.indexOf(name);
      if (otherLanguagesEn.length > 0) {
        otherLanguages = otherLanguagesEn;
      }
      return index !== -1
        ? englishNames[index].charAt(0).toUpperCase() + englishNames[index].slice(1)
        : name;
    });
  } else {
    transformedLanguages = [];
  }

  return (
    <div className={css.langContainer}>
      <img className={css.langImg} src={langImg} alt="languages all over the world" />
      <ul className={css.languageList}>
        {transformedLanguages.map((language, index) => (
          <li key={index}> {language}</li>
        ))}

        {otherLanguages.length >= 1 && <li> {otherLanguages}</li>}
      </ul>
    </div>
  );
};

export default Languages;
