import React from 'react';
import { useSelector } from 'react-redux';

const HouseRules = ({ houseRules, houseRulesDe }) => {
  const currentLanguage = useSelector(state => state.ui.language);

  // Split the text into sentences and filter empty strings
  const arrayOfStrings = houseRules && typeof houseRules==='string' && houseRules.split('.').filter(Boolean);
  const arrayOfStringsDe = houseRulesDe && typeof houseRulesDe==='string' && houseRulesDe.split('.').filter(Boolean);

  // Add a dot at the end of each sentence and trim any extra whitespace
  const houseRulesToRender =
    currentLanguage === 'de' && houseRulesDe !== 'Übersetzung hinzufügen' ? (
      <>
        {' '}
        {arrayOfStringsDe != '' ? arrayOfStringsDe.map((sentence, index) => (
          <p key={index}>{sentence}</p>
        )):''}
      </>
    ) : (
      <>
        {' '}
        {arrayOfStrings != '' ? arrayOfStrings.map((sentence, index) => (
          <p key={index}>{sentence}</p>
        )):''}
      </>
    );
  return <div>{houseRulesToRender}</div>;
};

export default HouseRules;
