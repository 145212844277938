import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from '../../../../util/reactIntl';

const PackList = ({ packListEn, packListDe, packList }) => {
  const currentLanguage = useSelector(state => state.ui.language);
  const arrayOfStrings = packListEn && typeof packListEn==='string' && packListEn.split('.').filter(Boolean);
  const arrayOfStringsDe = packListDe && typeof packListDe==='string' && packListDe.split('.').filter(Boolean);

  const packListToRender =
    currentLanguage === 'de' && packListDe !== 'Übersetzung hinzufügen' ? (
      <>
        {' '}
        {arrayOfStringsDe != '' ? arrayOfStringsDe.map((sentence, index) => (
          <p key={index}>{sentence}</p>
        )): ''}
      </>
    ) : (
      <>
        {' '}
        {arrayOfStrings != '' ? arrayOfStrings.map((sentence, index) => (
          <p key={index}>{sentence}</p>
        )): ''}
      </>
    );
  return <div>{packListToRender}</div>;
};

export default PackList;
