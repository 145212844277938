import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ResponsiveImage, IconSpinner } from '../../components';
import { propTypes } from '../../util/types';

import css from './ImageCarousel.module.css';

const KEY_CODE_LEFT_ARROW = 37;
const KEY_CODE_RIGHT_ARROW = 39;

class ImageCarousel extends Component {
  constructor(props) {
    super(props);

    const { selectedImg, images } = props;

    // Find the index of the selected image in the images array

    this.state = {
      selectedImageIndex: this.props.selectedImg,
      selectedImageLoaded: false,
    };

    this.onKeyUp = this.onKeyUp.bind(this);
    this.prev = this.prev.bind(this);
    this.next = this.next.bind(this);
  }

  componentDidMount() {
    if (typeof window !== 'undefined'){
    window.addEventListener('keyup', this.onKeyUp);
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined'){
    window.removeEventListener('keyup', this.onKeyUp);
    }
  }

  onKeyUp(e) {
    if (e.keyCode === KEY_CODE_LEFT_ARROW) {
      this.prev();
    } else if (e.keyCode === KEY_CODE_RIGHT_ARROW) {
      this.next();
    }
  }

  prev() {
    const { images } = this.props;
    const count = images.length;
    if (count < 2) {
      return;
    }
    this.setState(prevState => ({
      selectedImageIndex: (count + prevState.selectedImageIndex - 1) % count,
      selectedImageLoaded: false,
    }));
  }

  next() {
    const { images } = this.props;
    const count = images.length;
    if (count < 2) {
      return;
    }
    this.setState(prevState => ({
      selectedImageIndex: (count + prevState.selectedImageIndex + 1) % count,
      selectedImageLoaded: false,
    }));
  }

  markIt(index) {
    if (this.state.selectedImageIndex === index && !this.state.selectedImageLoaded) {
      this.setState({ selectedImageLoaded: true });
    }
  }
  render() {
    const { rootClassName, className, images, intl } = this.props;
    const { selectedImageIndex, selectedImageLoaded } = this.state;
    const classes = classNames(rootClassName || css.root, className);

    const naturalIndex = selectedImageIndex + 1;
    const imageIndex =
      images.length > 0 ? (
        <span className={css.imageIndex}>
          {naturalIndex}/{images.length}
        </span>
      ) : null;

    const prevButton =
      images.length > 1 ? (
        <button
          className={css.prev}
          onClick={e => {
            this.prev();
            e.stopPropagation(); // Stop the event propagation here
          }}
        />
      ) : null;

    const nextButton =
      images.length > 1 ? (
        <button
          className={css.next}
          onClick={e => {
            this.next();
            e.stopPropagation(); // Stop the event propagation here
          }}
        />
      ) : null;
    const imageAltText = intl.formatMessage(
      {
        id: 'ImageCarousel.imageAltText',
      },
      {
        index: naturalIndex,
        count: images.length,
      }
    );

    const currentImageIsLoaded = images.length === 0 || selectedImageLoaded;
    const loadingIconClasses = classNames(css.loading, {
      [css.loadingVisible]: !currentImageIsLoaded,
    });
    const imageClasses = classNames(css.image, {
      [css.imageLoading]: !currentImageIsLoaded,
    });

    const selectedImage = images[selectedImageIndex];

    return (
      <div className={classes}>
        <div className={css.imageWrapper}>
          <IconSpinner className={loadingIconClasses} />
          <ResponsiveImage
            className={imageClasses}
            alt={imageAltText}
            image={selectedImage}
            onLoad={() => this.markIt(selectedImageIndex)}
            variants={[
              selectedImage &&
              'landscape-crop2x' in selectedImage.attributes.variants &&
              !('scaled-large' in selectedImage.attributes.variants)
                ? 'landscape-crop2x'
                : 'scaled-large',
            ]}
            sizes="(max-width: 767px) 100vw, 80vw"
          />
        </div>
        {imageIndex}
        {prevButton}
        {nextButton}
      </div>
    );
  }
}

ImageCarousel.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string, arrayOf } = PropTypes;

ImageCarousel.propTypes = {
  rootClassName: string,
  className: string,
  images: arrayOf(propTypes.image).isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(ImageCarousel);
