import React, { useState, useEffect } from 'react';
import css from './AboutProject.module.css';
import placeholderPhoto from './PlaceholderAsset/placeholder.webp';
import { concatStrings } from '../../utils';
import { useIntl } from '../../../../util/reactIntl';

const AboutProject = props => {
  const [projectSrc, setProjectSrc] = useState('');
  const [projectInit, setProjectInit] = useState(false);
  const [detailsText, setDetailsText] = useState('');
  const [projectPic, setProjectPic] = useState('');
  const [hostPicture, setHostPicture] = useState('');
  const [fetchingInProgress, setFetchingInProgress] = useState(true);
  const intl = useIntl();
  const selectedLanguage = intl.locale;

  useEffect(() => {
    if (props) {
      if (props?.currentListing?.images && fetchingInProgress) {
        setProjectPic(props?.currentListing?.images[props?.currentListing?.images.length-1]?.attributes?.variants?.facebook?.url);
        if (projectPic != '') {
          setFetchingInProgress(false);
        }
      }
      setDetailsText(
        selectedLanguage == 'en'
          ? props?.currentListing?.author?.attributes?.profile?.publicData?.projectDescription
          : props?.currentListing?.author?.attributes?.profile?.publicData?.projectDescription_de
      );
      setHostPicture(
        props?.currentListing?.author?.profileImage?.attributes?.variants.facebook?.url ?? ''
      );
    }
  }, [props]);

  useEffect(() => {
    if (!projectInit) {
      const projectImageObj = props?.currentListing?.author?.attributes?.profile?.publicData
        ?.projectImage
        ? props?.currentListing?.author?.attributes?.profile?.publicData?.projectImage
        : '';
      if (projectImageObj != '') {
        let imgProject = concatStrings(projectImageObj);
        setProjectSrc(imgProject);
      }
      setProjectInit(true);
    }
  }, [props]);

  const [showSection, setShowSection] = useState(false);
  const [leftHalf, setLeftHalf] = useState('');
  const [rightHalf, setRightHalf] = useState('');

  const processParagraph = paragraph => {
    const words = paragraph.split(' ');
    if (words.length < 5) {
      setShowSection(false);
      return;
    }
    if (words.length > 105) {
      let middleIndex = Math.floor(words.length / 2);
      while (middleIndex < words.length - 1 && !words[middleIndex].match(/[\.\?\!]$/)) {
        middleIndex++;
      }
      setLeftHalf(words.slice(0, middleIndex + 1).join(' '));
      setRightHalf(words.slice(middleIndex + 1).join(' '));
      setShowSection(true);
      return;
    } else {
      setLeftHalf(paragraph);
      setShowSection(true);
      return;
    }
  };
  useEffect(() => {
    if (detailsText && detailsText != '') {
      processParagraph(detailsText);
    }
  }, [detailsText]);

  return (
    <>
      {showSection ? (
        <div className={css.outerWrapper}>
          <h2>Project: {props?.currentListing?.attributes?.title.split('• ')[0]}</h2>
          {leftHalf.length > 1 ? (
            <div className={css.firstRow}>
              <div className={css.leftSection}>
                <p className={css.paragraphsLeft}>{leftHalf}</p>
              </div>
              <div className={css.rightSection}>
                <img
                  className={css.projectPhotoRight}
                  src={ projectPic}
                  alt="socialbnb projects"
                />
              </div>
            </div>
          ) : (
            <div className={css.firstRowSwapped}>
              <div className={css.rightSectionSwapped}>
                <img
                  className={css.projectPhotoRight}
                  src={projectSrc != '' ? projectSrc : projectPic}
                  alt="socialbnb projects"
                />
              </div>
              <div className={css.leftSection}>
                <p className={css.paragraphsLeft}>{leftHalf}</p>
              </div>
            </div>
          )}

          {rightHalf.length > 1 ? (
            <div className={css.secondRow}>
              <div className={css.leftSection}>
                <img
                  className={css.projectPhotoLeft}
                  src={hostPicture != '' ? hostPicture : placeholderPhoto}
                  alt="socialbnb projects"
                />
              </div>
              <div className={css.rightSection}>
                <p className={css.paragraphsRight}>{rightHalf}</p>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default AboutProject;
